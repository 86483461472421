
<template>
    <div class="app-container pageContainer directoryBox">
        <el-row type="flex" justify="start">
                <el-tabs v-model="activeName" type="border-card" style="width: 100%;height: 100%;">
                    <el-tab-pane v-for="item in tabMapOptions" :key="item.key" :label="item.label" :name="item.key">

                        <el-table height="100%" :data="tableData" style="width: 100%;"  border stripe>
                            <el-table-column type="index" label="序号" width="60px"></el-table-column>
                            <el-table-column prop="course_name" label="课程名称"></el-table-column>
                            <el-table-column prop="course_code" label="课程代码"></el-table-column>
                            <el-table-column prop="sort" label="排序号"></el-table-column>
                            <el-table-column label="操作" min-widthth="80">
                                <template slot-scope="scope">
                                    <el-popconfirm width="250" confirmButtonText='确定' cancelButtonText='取消'
                                                   icon="el-icon-info" iconColor="red" @confirm="handleDel(scope.row)" placement="left"
                                                   title="是否确定删除？">
                                        <a slot="reference" size="small"
                                           style="cursor: pointer;color: red;font-size: 12px;margin-right: 10px;">删除</a>
                                    </el-popconfirm>
                                    <el-button @click="handleModify(scope.row)" type="text" size="small">修改</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane disabled>
                        <div slot="label">
                            <el-button size="small" icon="el-icon-plus" @click="formAdd" type="primary">新增</el-button>
                        </div>
                    </el-tab-pane>
                </el-tabs>
        </el-row>
        <el-dialog :title="modalTitle" :visible.sync="isShowModal" :close-on-click-modal="false"
                   :destroy-on-close="true">
            <el-form :model="formData" ref="ruleForm" label-width="120px" :rules="rules"
                     style="max-height: 450px;overflow-y: auto;min-height: 300px;">
                <el-form-item label="课程名称：" prop="course_name">
                    <el-input v-model.trim="formData.course_name" style="width: 50%" />
                </el-form-item>
                <el-form-item label="课程代码：" prop="course_code">
                    <el-input v-model.trim="formData.course_code" style="width: 50%" />
                </el-form-item>
                <el-form-item label="排序号：" prop="sort">
                    <el-input-number :min="0" v-model.trim="formData.sort" placeholder="请输入排序号(数字)"
                                     controls-position="right"/>
                </el-form-item>
            </el-form>
            <el-row>
                <el-col :span="22" style="text-align: right;">
                    <el-button size="small" type="default" @click="isShowModal = false">取消</el-button>
                    <el-button size="small" type="primary" @click="submitForm('ruleForm')">保存</el-button>
                </el-col>
            </el-row>
        </el-dialog>


    </div>
</template>
<script>
import api from '@/api/api';

export default {
    name: 'deptRole',
    data() {
        return {
            isShowModal: false,
            modalTitle: '添加',
            formData: {sort:1},
            //table
            tabMapOptions: [{
                label: '课程管理',
                key: 'CN'
            }, ],
            activeName: 'CN',
            tableData: [],
            rules: {
                course_name: [{
                    required: true,
                    message: '请输入课程名称',
                    trigger: 'blur'
                }],
                course_code: [{
                    required: true,
                    message: '请设置课程代码',
                    trigger: 'blur'
                }],
                sort: [{
                    required: true,
                    message: '请输入排序号',
                    trigger: 'blur'
                }],
            },
            tableData2:[],
            key_type:'',
        }
    },
    mounted() {
        this.getList();
    },
    methods: {
        getList() {
            let _this = this
            this.$http.post("/api/t_sch_course_list",).then(res => {
                this.tableData = res.data.data
            })
        },
        formAdd() {
            this.isShowModal = true;
            this.formData = {
                sort:1,
            }
            this.modalTitle = '添加课程'
        },
        handleModify(row) {
            this.isShowModal = true;
            this.modalTitle = '修改课程';
            this.formData = {}
            this.formData = {...row}
        },
        handleDel(row) {
            this.$http.post('/api/t_sch_course_delete', {
                id: row.id
            }).then(res => {
                this.$message.success('删除成功')
                this.getList();
            })
        },

        submitForm(formName) {
            let _this = this
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let params = {..._this.formData}
                    this.$http.post("/api/t_sch_course_edit", params).then(res => {
                        this.$message.success('保存成功')
                        this.getList();
                        this.isShowModal = false;
                    })
                }
            })
        },
    }
}
</script>
<style lang="less" type="text/less">
.directoryBox{
    height: 100%;

    .el-row,.el-row .el-col{
        height: 100%;
    }
    .el-tabs{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    .el-tabs__content{
        flex: 1;
        .el-tab-pane{
            height: 100%;
        }
    }
}
</style>
<style scoped lang="less" type="text/less">

</style>
